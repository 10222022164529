import { render, staticRenderFns } from "./add_ProductDigitaldata.vue?vue&type=template&id=d3222f88&"
import script from "./add_ProductDigitaldata.vue?vue&type=script&lang=js&"
export * from "./add_ProductDigitaldata.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports